"use client";

import Image from "next/image";
import { useMemo, useState } from "react";

interface FallbackImageProps {
	src: string;
	alt: string;
	className?: string;
	width?: number;
	height?: number;
	priority?: true;
	fill?: boolean;
	gradientFallback?: boolean;
}

function getRandomLinearGradient(): React.CSSProperties {
	const colors = [
		"#0077b6",
		"#6a0572",
		"#5c677d", // Cool Tones
		"#ff6b6b",
		"#ffb400",
		"#ff9f1c", // Warm Tones
		"#1e1e1e",
		"#f4f4f4",
		"#4a4a4a", // Neutrals
	];

	const getRandomColor = () =>
		colors[Math.floor(Math.random() * colors.length)];

	const color1 = getRandomColor();
	let color2;

	// Ensure the two colors are not the same
	do {
		color2 = getRandomColor();
	} while (color1 === color2);

	const angle = Math.floor(Math.random() * 360);

	return {
		background: `linear-gradient(${angle}deg, ${color1}, ${color2})`,
	};
}

export function FallbackImage({
	src,
	alt,
	className,
	priority,
	width,
	height,
	fill,
	gradientFallback,
}: FallbackImageProps) {
	const [imgSrc, setImgSrc] = useState<string>(src);
	const [imgLoadError, setImgLoadError] = useState<boolean>(false);

	const handleError = () => {
		if (!gradientFallback)
			setImgSrc("https://placehold.co/600x600/orange/white?text=?");
		setImgLoadError(true);
	};

	const gradientStyle = useMemo(() => getRandomLinearGradient(), []);

	return (
		<Image
			src={imgSrc}
			alt={alt}
			className={className}
			onError={handleError}
			width={width}
			height={height}
			priority={priority}
			fill={fill}
			style={imgLoadError && gradientFallback ? gradientStyle : {}}
		/>
	);
}
