"use client";

import { VideoThumbnail } from "@/components/video-thumb/video-thumb";
import type { Video_Feed } from "@/types/video";
import type { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Link from "next/link";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useSessionStorageState from "use-session-storage-state";

interface VideoCardProps {
	videos: Video_Feed[];
}

const OPTIONS: EmblaOptionsType = {
	loop: false,
	align: "center",
	skipSnaps: false,
};

export function HomeFeed({ videos }: VideoCardProps) {
	const [selectedIndex, setSelectedIndex] = useSessionStorageState<number>(
		"home-feed-video",
		{ defaultValue: 0 },
	);
	const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
	const options = { ...OPTIONS, startIndex: selectedIndex ?? 0 };
	const [emblaRef, emblaApi] = useEmblaCarousel(options);

	const scrollTo = useCallback(
		(index: number) => emblaApi?.scrollTo(index),
		[emblaApi],
	);

	const onSelect = useCallback(() => {
		if (!emblaApi) return;
		setSelectedIndex(emblaApi.selectedScrollSnap());
	}, [emblaApi]);

	useEffect(() => {
		if (!emblaApi) return;
		onSelect();
		setScrollSnaps(emblaApi.scrollSnapList());
		emblaApi.on("select", onSelect);
		return () => {
			emblaApi.off("select", onSelect);
		};
	}, [emblaApi, onSelect]);

	return (
		<div className="pb-2 pt-0">
			<div className="flex flex-col px-4">
				<Link
					href="/feed"
					className="mt-3  sm:mb-1 mb-2 flex flex-row justify-between hover:underline sm:border-0 border-b-1 border-slate-300 dark:border-slate-700"
				>
					<h2 className=" flex-1 text-xl pl-0.5 font-medium pb-0.5 ">
						Trending Videos
					</h2>
					<span className="self-end mb-1 text-[12px] text-muted-foreground">
						View All
					</span>
				</Link>
				<div className="relative">
					<div className="overflow-hidden" ref={emblaRef}>
						<div className="flex -ml-2 md:-ml-3">
							{videos.map((video, index) => (
								<div
									className="flex-[0_0_100%] md:flex-[0_0_60%] min-w-0 pl-2 md:pl-3 transition-all duration-350 ease-in-out"
									key={index + video.video_id}
									style={{
										opacity: index === selectedIndex ? 1 : 0.4,
										transform: `scale(${index === selectedIndex ? 1 : 0.98})`,
									}}
								>
									<div className="md:max-w-4xl mx-auto h-full">
										<VideoThumbnail
											index={index}
											channel_id={video.channel_id}
											video_id={video.video_id}
											title={video.title}
											game_id={video.game_id}
											slug={video.slug}
											bigThumb={true}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="flex justify-center gap-4 mt-4">
						{scrollSnaps.map((_, index) => (
							<button
								key={index}
								className={`sm:w-3 sm:h-3 h-3.5 w-3.5 rounded-full transition-all duration-200 ease-in-out ${index === selectedIndex ? "bg-primary w-6 sm:w-6" : "bg-secondary"}`}
								type="button"
								onClick={() => scrollTo(index)}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
